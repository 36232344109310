<template>
  <div>
    <el-container>
      <CommonTree
        :treeData="treeData"
        :defaultProps="defaultProps"
        :searchTitle="searchTitle"
        :isShowdig="true"
        :showCheckbox="false"
        :popover-show="true"
        :expandOnClickNode="false"
        treeTitle="知识库分类"
        @include-down="includeDown"
        @getTreeAdd="treeMenuAdd"
        @getTreeEdit="treeMenuEdit"
        @getTreeDelete="treeNodeDel"
        @getNodeClick="treeNodeClick"
      />
      <el-main>
        <head-layout
          :head-btn-options="headBtnOptions"
          head-title="维度分类"
          @head-remove="headRemove"
          @head-add-tabs="headAdd"
        ></head-layout>
        <grid-head-layout
          ref="gridHeadLayout"
          :grid-head-btn="gridHeadBtn"
          :search-columns="searchColumns"
          @grid-head-search="gridHeadSearch"
          @grid-head-empty="gridHeadEmpty"
        ></grid-head-layout>
        <grid-layout
          ref="gridLayOut"
          :grid-row-btn="gridRowBtn"
          :table-options="tableOptions"
          :table-data="tableData"
          :table-loading="crudLoading"
          :data-total="page.total"
          :page="page"
          @grid-row-detail-click="rowView"
          @page-current-change="onLoad"
          @page-size-change="onLoad"
          @page-refresh-change="onLoad"
          @gird-handle-select-click="selectionChange"
          @row-remove="rowRemove"
          @row-edit="rowEdit"
        ></grid-layout>
      </el-main>
    </el-container>

    <common-dialog
      v-if="showTreeDialog"
      width="60%"
      class="qmDialog"
      dialogTitle="知识库分类管理"
      :show-fullscreen="true"
      @cancel="showTreeDialog = false"
      @confirm="handleTreeNodeSave">
      <avue-form
        :option="treeDialogOption"
        ref="addForm"
        v-model="form"
        @submit="treeNodeSave"
      ></avue-form>
    </common-dialog>
    <common-dialog
      v-if="showDialog"
      width="60%"
      dialogTitle="知识分类"
      :show-confirm-btn="formType != 'view'"
      @cancel="closeDialog"
      @confirm="handleNodeSave">
      <avue-form
        :option="dialogOption"
        ref="addFormList"
        v-model="formList"
        @submit="saveList"
      ></avue-form>
    </common-dialog>
  </div>
</template>

<script>

let previousRouterName = "";
let selectClassifyId = "";
import {
  categoryHeadPage,
  remove,
  saveList,
  getKnowledgeClassifyTree,
  saveKnowledgeClassifyTree,
  removeKnowledgeClassify
} from "@/api/knowledgeClassifcation/knowledge";
import CommonTree from "@/views/components/com_tree";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import CommonDialog from "@/components/CommonDialog";
import ServerNameEnum from "../../../../../util/ServerNameEnum";

export default {
  name:"knowledgeClassification",
  data() {
    return {
      form: {},
      query: {},
      treeReadOnlyFlag: false,
      tableData: [],
      treeLoading: false,
      crudLoading: false,
      treeData: [],
      dicData: [],
      selectionList: [],
      node: {}, //左侧树node
      fullscreen: false,
      showTreeDialog: false,
      classifyId: '',
      searchTitle: "classifyName",
      defaultProps: {
        label: "classifyName",
        value: "classifyCode",
        key: "classifyCode",
      },
      showDialog: false,
      formType: '',
      formList: {},
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
    };
  },
  computed: {
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    headBtnOptions() {
      return [
        {
          label: "新增",
          emit: "head-add-tabs",
          type: "button",
          icon: "",
          btnOptType: "add",
        },
        {
          label: "删除",
          emit: "head-remove",
          type: "button",
          icon: "",
          btnOptType: "remove",
        },
      ];
    },
    tableOptions() {
      return {
        index: true,
        indexLabel: "序号",
        menuWidth: 220,
        selection: true,
        linklabel: "cateName",
        column: [
          {
            label: "维度名称",
            prop: "cateName",
            align: "left",
            // width: 280,
            overHidden: true,
          },
          {
            label: "维度编码",
            prop: "cateCode",
            align: "center",
            width: 280,
            overHidden: true,
          },
          // {
          //   label: "维度英文缩写",
          //   prop: "cateEn",
          //   align: "center",
          //   width: 120,
          //   overHidden: true,
          // },
        ],
      };
    },
    gridHeadBtn() {
      return [];
    },
    searchColumns() {
      return [
        {
          label: "",
          prop: "cateName",
          span: 4,
          placeholder: "请输入维度名称",
        },
        {
          label: "",
          prop: "cateCode",
          span: 4,
          placeholder: "请输入维度编号",
        },
      ];
    },
    gridRowBtn() {
      return [
        {
          label: "编辑",
          emit: "row-edit",
          type: "button",
          icon: "",
        },
        {
          label: "删除",
          emit: "row-remove",
          type: "button",
          icon: "",
        },
      ];
    },
    treeDialogOption() {
      return {
        size: "small",
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 120,
        column: [
          {
            label: "分类名称",
            prop: "classifyName",
            placeholder: "请输入知识库分类名称",
            maxlength: 20,
            rules: [
              {
                required: true,
                message: "请输入知识库分类名称",
                trigger: ["blur", "change", "click"],
              },
            ],
          },
          {
            label: "分类编码",
            prop: "classifyCode",
            maxlength: 20,
            placeholder: "知识库分类编码自动带出",
            disabled: true,
            // rules: [
            //   {
            //     required: true,
            //     message: "请输入知识库分类编码",
            //     trigger: ["blur", "change", "click"],
            //   },
            // ],
          },
          {
            label: "上级类型",
            prop: "parentId",
            type: "tree",
            props: {
              label: "classifyName",
              value: "id",
              key: "parentId",
            },
            placeholder: "上级类型",
            dicUrl: `${ServerNameEnum.SERVER_KNOWLEDGE}/knowledgeClassify/tree`,
            nodeClick(row) {
              let length = row.ancestorsId.split(',').length;
              if (length == 5) {
                that.$nextTick(() => {
                  that.form.parentId = '';
                  that.form.$parentId = '';
                })
                that.$message({
                  type: "warning",
                  message: "分类最多添加五层",
                });
              }
            }
          },
          {
            label: "描述",
            prop: "remark",
            maxlength: 20,
            placeholder: "请输入描述",
          },
        ],
      };
    },
    dialogOption() {
      return {
        size: "small",
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 120,
        column: [
          {
            label: "分类名称",
            prop: "cateLineName",
            placeholder: "请输入分类名称",
            disabled: !['edit', 'add'].includes(this.formType),
            maxlength: 20,
            rules: [
              {
                required: true,
                message: "请输入分类名称",
                trigger: ["blur", "change", "click"],
              },
            ],
          },
          {
            label: "分类编号",
            prop: "cateLineCode",
            placeholder: "请输入分类编号",
            maxlength: 20,
            disabled: !['edit', 'add'].includes(this.formType),
            rules: [
              {
                required: true,
                message: "请输入分类编号",
                trigger: ["blur", "change", "click"],
              },
            ],
          },
          {
            label: "描述",
            prop: "remark",
            type: "textarea",
            rows: 3,
            span: 24,
            placeholder: "请输入描述",
            maxlength: 200,
            showWordLimit: true,
            disabled: !['edit', 'add'].includes(this.formType),
            rules: [
              {
                required: false,
                message: "请输入描述",
                trigger: ["blur", "change", "click"],
              },
            ],
          },
        ],
      };
    },
  },
  components: {
    GridLayout,
    HeadLayout,
    CommonTree,
    CommonDialog,
  },
  beforeRouteEnter(to, from, next) {
    selectClassifyId = from.params.knowledgeClassifyId;
    next();
  },
  created() {
    this.initTree()
  },
  mounted() {
    this.classifyId = ''
  },
  activated(){
    if(this.node){
      this.onLoad(this.page, this.query);
    }
  },
  methods: {
    fullClick() {
      this.fullscreen = !this.fullscreen;
    },
    // 左侧树新增
    treeMenuAdd() {
      this.form = {};
      this.treeReadOnlyFlag = false;
      this.fullscreen = false;
      this.showTreeDialog = true;
    },

    // 左侧树编辑
    treeMenuEdit(data) {
      this.treeReadOnlyFlag = true;
      this.fullscreen = false;
      this.showTreeDialog = true;
      this.form = data;
    },
    initTree() {
      getKnowledgeClassifyTree()
        .then((result) => {
          this.treeData = result.data.data;
          this.node = this.treeData[0];
          this.treeLoading = false;
          // 判断是否是由知识库分类编辑页面返回
          if (selectClassifyId !== undefined) {
            this.returnTreeNodeClick(selectClassifyId);
          } else {
            this.onLoad(this.page, {});
          }
        })
        .catch((err) => {
          this.treeLoading = true;
        });
    },
    // 新增左侧树节点
    handleTreeNodeSave() {
      this.$refs.addForm.submit();
    },
    // 新增左侧树节点
    treeNodeSave(data, done) {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.$loading();
          saveKnowledgeClassifyTree(this.form)
            .then((res) => {
              const {msg, data} = res.data;
              this.treeReadOnlyFlag = true;
              this.$message.success(msg);
              this.initTree();
              this.showTreeDialog = false;
            }).finally(() => {
            this.$loading().close();
          })
            .catch((err) => {
              done();
            });
        }
      });
    },
    // 删除左侧树节点
    treeNodeDel(data, done) {
      if (data.children.length != 0) {
        this.$message({
          type: "warning",
          message: "当前分类下有子级，禁止删除",
        });
        return;
      }
      this.$confirm("确认删除数据", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          removeKnowledgeClassify(data.id).then((res) => {
            if (res.data.data) {
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.initTree();
            } else {
              this.$message.warning("删除失败，当前知识库分类下绑定有维度分类！")
            }
          });
        })
        .catch(() => {
        });
    },

    // 点击左侧树节点
    treeNodeClick(node) {
      this.node = node;
      this.query.knowledgeClassifyId = this.node.id;
      this.classifyId = this.node.id;
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    // 返回左侧树节点点击事件
    returnTreeNodeClick(id) {
      this.query.knowledgeClassifyId = id;
      this.classifyId = id;
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    includeDown(e) {
      this.page.currentPage = 1;
      this.query.isInclude = e;
      this.onLoad(this.page);
    },
    //知识分类新增
    closeDialog() {
      this.formList = {};
      this.$refs.addFormList.resetForm();
      this.showDialog = false
    },
    handleNodeSave() {
      this.$refs.addFormList.submit();
    },
    saveList(data, done) {
      this.$refs.addFormList.validate((valid) => {
        if (valid) {
          this.$loading();
          let params = {
            ...this.formList,
            cateCode: this.node.cateCode,
            cateEn: this.node.cateEn,
            cateName: this.node.cateName,
            cateId: this.node.id,
            knowledgeClassify: this.node.knowledgeClassify
          }
          if (this.formList.id) {
            params.id = this.formList.id
          }
          saveList(params)
            .then((res) => {
              const {msg, data} = res.data;
              this.$message.success(msg);
              this.formList = {};
              this.$refs.addFormList.resetForm();
              this.onLoad(this.page, {});
              this.showDialog = false;
            }).finally(() => {
            this.$loading().close();
          })
            .catch((err) => {
              done();
            });
        }
      });
    },
    headRemove() {
      if (this.selectionList.length === 0) {
        this.$message.warning("请选择数据");
        return;
      }
      this.$confirm("确定将选择数据删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          remove(this.ids).then(() => {
            this.onLoad(this.page, {});
            this.$message({
              type: "success",
              message: "操作成功",
            });
            this.selectionClear();
          });
        })
        .catch(() => {
        });
    },

    rowRemove(row) {
      this.$confirm("确定删除数据", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          remove(row.id).then((res) => {
            if (res.data.data) {
              this.onLoad(this.page, {});
              this.$message({
                type: "success",
                message: "删除成功",
              });
            } else {
              this.$message.warning("删除失败，当前维度下绑定有子知识分类！")
            }
          });
        })
    },
    rowEdit(row) {
      this.$router.push({
        path: '/knowledge/knowledgeSetting/knowledgeClassification/form',
        query: {
          row: JSON.stringify(row),
          type: 'edit',
        }
      });
    },
    rowView(row) {
      this.$router.push({
        path: '/knowledge/knowledgeSetting/knowledgeClassification/form',
        query: {
          row: JSON.stringify(row),
          type: 'view',
        }
      });
    },

    // 新增
    headAdd() {
      if (this.classifyId === '') {
        this.$message.warning("请先选择左侧知识库分类")
      } else {
        this.$router.push({
          path: "/knowledge/knowledgeSetting/knowledgeClassification/form",
          query: {
            type: "add",
            classifyId: this.classifyId
          },
        });
      }
    },

    gridHeadSearch() {
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    gridHeadEmpty() {
      this.query = {};
      this.onLoad(this.page);
    },
    selectionClear() {
      this.selectionList = [];
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.crudLoading = true;
      categoryHeadPage(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.$refs.gridHeadLayout.searchForm, this.query)
      )
        .then((res) => {
          const data = res.data.data;
          this.$refs.gridLayOut.page.total = data.total;
          this.tableData = data.records;
          this.crudLoading = false;
          this.selectionClear();
        })
    },
    selectionChange(list) {
      this.selectionList = list;
    },
  },
};
</script>

